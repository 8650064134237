import cx from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import shuffle from 'lodash.shuffle';
import take from 'lodash.take';
import { useMemo } from 'react';

import { Merchant } from '../../../types';
import useIsSSR from '../../../utils/use-is-ssr';
import styles from './HightlightShops.module.scss';

const PICK_AMOUNT = 6;

const HightlightShops = ({
  merchants,
  className,
}: {
  merchants: Merchant[];
  className?: string;
}) => {
  const { t } = useI18next();
  // Use isSSR to force a client side render to make the order shuffles on every page view
  const isSSR = useIsSSR();
  const shops = useMemo(() => {
    return take(
      shuffle(merchants.filter((merchant) => !!merchant.logo)),
      PICK_AMOUNT
    );
  }, [merchants, isSSR]);

  return (
    <section
      id="highlight-shops"
      className={cx(className, styles.highlightsContainer)}
    >
      <div className={styles.highlights}>
        <h2>{t('merchant-2-highlight-shops-title')}</h2>
        <div className={styles.box}>
          {shops.map((shop) => {
            return (
              <img
                key={shop.slug}
                src={`${shop.logo?.url}?w=256`}
                alt={shop.title}
                className={styles.highlight}
              />
            );
          })}
        </div>
        <div className={styles.actions}>
          {/* <Link to="/smartpay-shops/">{t('view-all-shops')}</Link> */}
        </div>
      </div>
    </section>
  );
};

export default HightlightShops;
